import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link } from 'react-router-dom'


import { Contact, Betting } from "./data";

const HomeScreen = () => {
  const [mobileNavActive, setMobileNavActive] = useState(false);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <main>
      <div style={{display:"none",justifyContent:"center",alignItems:"center",position:"fixed",width:"100%",height:"100%",background:"rgb(0 0 0 / 66%)",zIndex:1070}}>
        <div style={{height:500,width:500}}>
        <video style={{width:"100%",height:"100%"}} controls="">
                <source src="./Videoo/SampleVideo.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
      </div>
      <div className="container">
        <div
          style={{ backgroundImage: `url("/assets/Images/homeImage.jpg")` }}
          className="front"
          id="web1"
        >
          <div>
            <div className="header">
              <div className="mobileNav">
                <a href="#web1">
                  <img
                    style={{ height: 60, width: 60 }}
                    src="/assets/Images/logo2.png"
                  />
                </a>
                {!mobileNavActive && (
                  <a class="menuburger" href="javascript:void(0)">
                    <img
                      onClick={() => {
                        setMobileNavActive(true);
                      }}
                      style={{ height: 30 }}
                      src="/assets/Images/burger.png"
                      alt="Swamiji Logo"
                    />
                  </a>
                )}
                {mobileNavActive && (
                  <a class="menuburger" href="javascript:void(0)">
                    <img
                      onClick={() => {
                        setMobileNavActive(false);
                      }}
                      style={{ height: 30 }}
                      src="/assets/Images/burger.png"
                      alt="Swamiji Logo"
                    />
                  </a>
                )}
                {mobileNavActive && (
                  <nav>
                    <ul className="mobileViewList">
                      <li>
                        <a href="#web2">About Us</a>
                      </li>
                      <li>
                        <a href="#web3">Our Partners</a>
                      </li>
                      <li>
                        <a href="#web4">How It Works</a>
                      </li>

                      <li>
                        <a href="#web6">Why Taj4News</a>
                      </li>
                      <li>
                        <a href="#web7">Contact Us</a>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>

              <ul className="webNav">
                <li>
                  <a href="#web2">About Us</a>{" "}
                </li>
                <li>
                  <a href="#web3">Our Partner </a>
                </li>

                <li>
                  <a href="#web4">How It Works</a>{" "}
                </li>

                <a href="#web1">
                  <img src="/assets/Images/logo2.png"/>
                </a>

                <li>
                  <a href="#web6">Why Taj4News</a>{" "}
                </li>
                <li>
                  <a href="#web7">ContactUs</a>{" "}
                </li>
                <li>
                  <a href="#web7"></a>{" "}
                </li>
              </ul>
            </div>
          </div>

          <div className="Home-Container">
            <h3>Get Your Cricket Id With Biggest</h3>
            <pre>Exchange</pre>
            <p>No.1 Platform For Providing Online Betting Cricket Id</p>
            {/* <div>
              <a>Get Your Id Now</a>
            </div> */}
          </div>

          {/* <div class="lb_sld_img lb_sld_img_fix">
            <img
              class="sicard ani anim"
              src="/assets/Images/cardbig.png"
              alt=""
            />
          </div> */}

          {/* <div class="lb_sld_img lb_sld_img_fix">
            <img
              class="sicardd ani anim"
              src="/assets/Images/s8.png"
              alt=""
            />
          </div> */}

          
        </div>

        <div style={{ background: "black" }}>
          <div className="ContactInfo">
            <p>
              <span>Whatsapp </span> Us On
            </p>
            <div className="ContactNumbers">
              <a href="https://wa.me/447537171314" target="_blank">
                +44 7537 171314
              </a>

              <a href="https://wa.me/8140893333" target="_blank">
                +91 8140893333
              </a>
              <a href="https://wa.me/8140313333" target="_blank">
                +91 8140313333
              </a>
            </div>
          </div>
        </div>

        <div
          style={{
            background: "black",
            display: "flex",
          }}
          class="lb_walb"
          id="web2"
        >
          <div class="lb_walb_img">
            <img class="lb_walb_img_bat" src="/assets/Images/logo2.png" alt="" />
          </div>
          <div class="lb_walb_con">
            <h4>
              Welcome TO <span>Taj4News</span>
            </h4>
            <h2>BEST CRICKET ID PROVIDER IN World</h2>
            <p>
              Online Betting is a great way to Earn Extra Money! Multiple
              matches &amp; predicting their outcome can become challenging but
              not anymore.Taj4News is the best online Cricket ID provider for
              Cricket offering our customers a unique and exciting gaming
              experience.For more details please contact us via WhatsApp. We
              will reply to you back and give all the required help/assistance.
            </p>
            <div class="lb_walb_btn">
              <a href="https://wa.me/447537171314" target="_blank" class="a_wa">
                Whatsapp us on - +44 7537 171314
              </a>
              <a href="https://wa.me/447537171314" target="_blank" class="a_di">
                Get Your ID Now
              </a>
            </div>
          </div>

          {/* <img class="lb_walb_card" src="images/cardbig.png" alt=""> */}
        </div>

        <div
          style={{ background:"black" }}
          className="PartnerContainer"
          id="web3"
        >
          <div className="PartnerContainerMain">
            <h1>
              <span style={{ color: "red" }}>Our</span> Partners
            </h1>
            <div className="FlipBoxesContainers">
              {Betting.map((value, index) => (
                <div className="FlipBox">
                  <img src={value.logo} />
                  <h4>{value.name}</h4>
                  <a href="https://wa.me/447537171314" target="_blank">Get Id</a>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          style={{ background:"black" }}
          class="lb_hiw"
          id="web4"
        >
          <div class="main">
            <h2>
              <span>How</span> it works
            </h2>

            <ul class="hiw_counter">
              <li class="active">
                <div>
                  <div class="hiw_step">
                    <span>Step</span>
                    <img class="num_img" src="/assets/Images/1.png" alt="" />
                  </div>
                </div>

                <h4>Message us on our WhatsApp number</h4>
                <div class="step_arrow">
                  <img
                    class="sa_img active"
                    src="/assets/Images/redarrow.png"
                    alt=""
                  />
                  <img
                    class="sa_img active"
                    src="/assets/Images/redarrow.png"
                    alt=""
                  />
                  <img
                    class="sa_img active"
                    src="/assets/Images/redarrow.png"
                    alt=""
                  />
                  <img
                    class="sa_img active"
                    src="/assets/Images/redarrow.png"
                    alt=""
                  />
                  <img
                    class="sa_img active"
                    src="/assets/Images/redarrow.png"
                    alt=""
                  />
                </div>
              </li>
            </ul>

            <h3>
              Taj4News is The Biggest Platform For Providing Online Sports & Casino
              Betting ID's.
            </h3>
            <h5>Get Your Betting Id Now. Contact Us On WhatsApp.</h5>
            <p>
              For Any Queries, Emergencies, Feedbacks or Complaints. We Are Here
              To Help You 24/7 With Our Online Services.
            </p>

            <div class="hiw_wa">
              <h2>
                <span>Whatsapp</span> us on
              </h2>
              <div class="wa_link">
                <a href="https://wa.me/447537171314" target="_blank" class="a_wa">
                  +44 7537 171314
                </a>
                <a href="https://wa.me/8140893333" target="_blank" class="a_wa">
                  +91 8140893333
                </a>
                <a href="https://wa.me/8140313333" target="_blank" class="a_wa">
                  +91 8140313333
                </a>
              </div>
            </div>
          </div>
        </div>


        <div
          style={{ background:"black" }}
          className="ContactContainer"
          id="web6"
        >
          <div style={{ width: "90%" }} className="PartnerContainerMain">
            <h1>
              <span style={{ color: "red" }}>Why</span> Taj4News
            </h1>
            <div className="FlipBoxesContainer">
              {Contact.map((con, index) => (
                <div className="ContactBox">
                  <div style={{ height: "60%" }}>
                    <img
                      style={{ height: 50, width: 50, marginRight: 20 }}
                      src={con.image}
                    />
                  </div>
                  <div>
                    <h4>{con.features}</h4>
                    <p>{con.about}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <footer id="web7" className="Footer">
          <h3>Get In Touch With Taj4News Customer Care</h3>
          <p>
            For Any Queries, Emergencies, Feedbacks or Complaints. We Are Here
            To Help You 24/7 With Our Online Services.
          </p>

          <div className="FooterContact">
            <h5>
              <span style={{ color: "red" }}>Whatsapp </span> Us On
            </h5>
            <div className="FooterContactNumbers">
              <a href="https://wa.me/447537171314" target="_blank">
                +44 7537 171314
              </a>
              <a href="https://wa.me/8140893333" target="_blank">
                +91 8140893333
              </a>
              <a href="https://wa.me/8140313333" target="_blank">
                +91 8140313333
              </a>
            </div>
          </div>

          <h3>With Our Step-By-Step Guide For Beginners</h3>
          <p>We Provide 24/7 Service For Our Customers.</p>
          {/* <a>+91 8882720178</a> */}

          <div class="ftr_links">
            <a href="#web1">Home</a>
            <a href="#web2">About Us</a>
            <a href="#web3">Our Partners</a>
            <a href="#web4">How It Works</a>
            <a href="#web6">Why Taj4News</a>
            <a href="#web7">Contact Us</a>
            <Link to ="/terms">Terms &amp; Conditions</Link>
            <Link to="/legal">Legal Situation</Link>
          </div>

          <div class="ftr_social">
            <a target="_blank">
              <img src="/assets/Images/ss1.png" alt="" />
            </a>
            <a target="_blank">
              <img src="/assets/Images/ss2.png" alt="" />
            </a>
            <a target="_blank">
              <img src="/assets/Images/ss3.png" alt="" />
            </a>
            <a target="_blank">
              <img src="/assets/Images/ss4.png" alt="" />
            </a>
          </div>
        </footer>
      </div>
    </main>
  );
};

export default HomeScreen;

import React, { useEffect, useState } from "react";
import "./Legal.css";
import { Link } from 'react-router-dom'


const Legal = () => {
  const [mobileNavActive, setMobileNavActive] = useState(false);

  return (
    <main>
    
      <div className="container">
        <div
          style={{ background:"black",height:"auto"}}
          className="front"
          id="web1"
        >
          <div>
            <div className="header">
              <div className="mobileNav">
                <a href="#web1">
                  <img
                    style={{ height: 60, width: 60 }}
                    src="/assets/Images/logo.png"
                  />
                </a>
                {!mobileNavActive && (
                  <a class="menuburger" href="javascript:void(0)">
                    <img
                      onClick={() => {
                        setMobileNavActive(true);
                      }}
                      style={{ height: 30 }}
                      src="/assets/Images/burger.png"
                      alt="Swamiji Logo"
                    />
                  </a>
                )}
                {mobileNavActive && (
                  <a class="menuburger" href="javascript:void(0)">
                    <img
                      onClick={() => {
                        setMobileNavActive(false);
                      }}
                      style={{ height: 30 }}
                      src="/assets/Images/burger.png"
                      alt="Swamiji Logo"
                    />
                  </a>
                )}
                {mobileNavActive && (
                  <nav>
                    <ul className="mobileViewList">
                      <li>
                        <Link to="/#web2">About Us</Link>
                      </li>
                      <li>
                        <Link to="/#web3">Our Partners</Link>
                      </li>
                      <li>
                        <Link to="/#web4">How It Works</Link>
                      </li>

                    
                      <li>
                        <Link to="/#web6">Why Taj4News</Link>
                      </li>
                      <li>
                        <Link to="/#web7">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>

              <ul className="webNav">
                <li>
                  <Link to="/#web2">About Us</Link>{" "}
                </li>
                <li>
                  <Link to="/#web3">Our Partner </Link>
                </li>

                <li>
                  <Link to="/#web4">How It Works</Link>{" "}
                </li>

                <Link to="/#web1">
                  <img src="/assets/Images/logo2.png" />
                </Link>

                <li>
                  <Link to="/#web6">Why Taj4News</Link>{" "}
                </li>
                <li>
                  <Link to="/#web7">ContactUs</Link>{" "}
                </li>
                 <li>
                  <Link to="/#web7"></Link>{" "}
                </li>
              </ul>
            </div>
          </div>

     
		<div className="Privacy">
          <h3>
Answer: Yes, online betting is legal in India.</h3>
<h4 style={{color:"#ec972f"}}>There are no federal laws against online betting in India.</h4>
<p>
Each state may decide if they want to impose laws on online betting. And so far, only a few states in India have made laws against online betting.</p>
<p>
In summary, this means that online betting is legal in India, with the exception of a few states.
</p>

<h3>LEGAL DISCLAIMER</h3>
<p>Please be advised: We are not lawyers, advocates, or legal experts.
</p>
<p>The <a href="https://iclg.com/practice-areas/gambling-laws-and-regulations/india" style={{color:'blue'}}><u>legal information</u></a> contained within this article has been compiled through careful research and conversations with legal experts on the topic.
</p>
<p>We have done all that we can to answer the question truthfully, but if you want a legally binding answer, we advise that you contact a lawyer for yourself.		
</p>

</div>   
        </div>


       

     
        <footer id="web7" className="Footer">
         

          


         <div class="ftr_links">
           <Link to="/#web1">Home</Link>
           <Link to="/#web2">About Us</Link>
           <Link to="/#web3">Our Partners</Link>
           <Link to="/#web4">How It Works</Link>
           <Link to="/#web6">Why Taj4News</Link>
           <Link to="/#web7">Contact Us</Link>
          
           <Link to="/terms">Terms &amp; Conditions</Link>

           <Link to="/legal">Legal Situation</Link>
         </div>

         <div class="ftr_social">
           <a target="_blank">
             <img src="/assets/Images/ss1.png" alt="" />
           </a>
           <a target="_blank">
             <img src="/assets/Images/ss2.png" alt="" />
           </a>
           <a target="_blank">
             <img src="/assets/Images/ss3.png" alt="" />
           </a>
           <a target="_blank">
             <img src="/assets/Images/ss4.png" alt="" />
           </a>
         </div>
       </footer>
      </div>
    </main>
  );
};

export default Legal;


import React, { useEffect, useState } from "react";
import "./Terms.css";
import { Link } from 'react-router-dom'

const Terms = () => {
  const [mobileNavActive, setMobileNavActive] = useState(false);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".header");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <main>
    
      <div className="container">
        <div
          style={{ background:"black",height:"auto"}}
          className="front"
          id="web1"
        >
          <div>
            <div className="header">
              <div className="mobileNav">
                <a href="#web1">
                  <img
                    style={{ height: 60, width: 60 }}
                    src="/assets/Images/logo.png"
                  />
                </a>
                {!mobileNavActive && (
                  <a class="menuburger" href="javascript:void(0)">
                    <img
                      onClick={() => {
                        setMobileNavActive(true);
                      }}
                      style={{ height: 30 }}
                      src="/assets/Images/burger.png"
                      alt="Swamiji Logo"
                    />
                  </a>
                )}
                {mobileNavActive && (
                  <a class="menuburger" href="javascript:void(0)">
                    <img
                      onClick={() => {
                        setMobileNavActive(false);
                      }}
                      style={{ height: 30 }}
                      src="/assets/Images/burger.png"
                      alt="Swamiji Logo"
                    />
                  </a>
                )}
                {mobileNavActive && (
                  <nav>
                    <ul className="mobileViewList">
                      <li>
                        <Link to="/#web2">About Us</Link>
                      </li>
                      <li>
                        <Link to="/#web3">Our Partners</Link>
                      </li>
                      <li>
                        <Link to="/#web4">How It Works</Link>
                      </li>
                      <li>
                        <Link to="/#web6">Why Taj4News</Link>
                      </li>
                      <li>
                        <Link to="/#web7">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>

              <ul className="webNav">
                <li>
                  <Link to="/#web2">About Us</Link>{" "}
                </li>
                <li>
                  <Link to="/#web3">Our Partner </Link>
                </li>

                <li>
                  <Link to="/#web4">How It Works</Link>{" "}
                </li>

                <Link to="/#web1">
                  <img src="/assets/Images/logo2.png" />
                </Link>

               
                <li>
                  <Link to="/#web6">Why Taj4News</Link>{" "}
                </li>
                <li>
                  <Link to="/#web7">ContactUs</Link>{" "}
                </li>
                <li>
                  <a href="#web7"></a>{" "}
                </li>
              </ul>
            </div>
          </div>

     
		
	<div className="Privacy">

      
          <h3>
General Terms</h3>
<p>
We reserve the right to amend the terms (including to any documents referred and linked to below) at any time. When such amendment is not substantial, we may not provide you with prior notice. You will be notified in advance for material changes to the terms and may require you to re-confirm acceptance to the updated terms before the changes come into effect. If you object to any such changes, you must immediately stop using the service and the termination provisions below will apply. Continued use of the service indicates your agreement to be bound by such changes. Any Prediction not settled prior to the changed terms taking effect will be subject to the pre-existing terms.
</p>
<p>
If at any time you are in any doubt about how to place Prediction or otherwise use the service you should refer back to these terms or contact our customer service department (Customer Service Department)
</p>
<h3>1. Your Obligations
</h3>
<p>
You agree that at all times when using the Service:
</p><p>
You are over 18 years of age (or over the age of majority as stipulated in the laws of the jurisdiction applicable to you) and can enter into a binding legal agreement with us.
</p>
<p>
It is the User’s responsibility to check and enter this site only if the user is in a country where it is lawful to place Prediction on the service (if in doubt, you should seek local legal advice). It is your responsibility to ensure that your use of the service is legal.
</p>
<p>
When sending money to us you are authorised to do so e.g. you are the authorised user of the debit/credit card or other payment method you use.
</p>
<p>
You will not, by participating in the Services and/or placing Prediction be placed in a position of actual, potential or perceived conflict of interest in any manner.
</p><p>
You have never failed to pay, or attempted to fail to pay a liability on a Prediction.
</p>
<p>
You are acting solely on your own behalf as a private individual in a personal capacity and not on behalf of another party or for any commercial purposes.
</p>
<p>By placing Prediction you may lose some or all of your money lodged with us in accordance with these terms and you will be fully responsible for that loss.
</p>
<p>You must use the service for legitimate Prediction purposes only and must not nor attempt to manipulate any market or element within the service in bad faith or in a manner that adversely affects the integrity of the Service or us.
</p>
<p>When placing Prediction on the service you must not use any information obtained in breach of any legislation in force in the country in which you were when the bet was placed.
</p>


</div>	
          
        </div>


       

     
        <footer id="web7" className="Footer">
         

          


         <div class="ftr_links">
           <Link to="/#web1">Home</Link>
           <Link to="/#web2">About Us</Link>
           <Link to="/#web3">Our Partners</Link>
           <Link to="/#web4">How It Works</Link>
           <Link to="/#web6">Why Taj4News</Link>
           <Link to="/#web7">Contact Us</Link>
         
           <Link to="/terms">Terms &amp; Conditions</Link>

           <Link to="/legal">Legal Situation</Link>
         </div>

         <div class="ftr_social">
           <a target="_blank">
             <img src="/assets/Images/ss1.png" alt="" />
           </a>
           <a target="_blank">
             <img src="/assets/Images/ss2.png" alt="" />
           </a>
           <a target="_blank">
             <img src="/assets/Images/ss3.png" alt="" />
           </a>
           <a target="_blank">
             <img src="/assets/Images/ss4.png" alt="" />
           </a>
         </div>
       </footer>
      </div>
    </main>
  );
};

export default Terms;


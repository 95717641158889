const Betting= [
    {
        logo:"/assets/Images/taj.png",
        name:"taj777.com"
    },
    {
        logo:"/assets/Images/world.png",
        name:"world777.com"
    },
    {
        logo:"/assets/Images/unity.png",
        name:"unity777.co"
    },
    {
        logo:"/assets/Images/daimond.png",
        name:"diamondexch999.com"
    },
    {
        logo:"/assets/Images/navkar.png",
        name:"navkar999.com"
    },
    {
        logo:"/assets/Images/unity247.png",
        name:"unity247.com"
    },
    {
        logo:"/assets/Images/tenexch.png",
        name:"tenexch.com"
    },
    {
        logo:"/assets/Images/lotus.jpeg",
        name:"lotusbook247.com"
    }
]


const Contact= [
    {
        image:"/assets/Images/ic1.png",
        features:"Online Id",
        about:"Get your online cricket/sports id with us and instantly start Playing. Message us to get your ID now."
    }
   ,
   {
    image:"/assets/Images/ic2.png",
    features:"24 /7 SUPPORT",
    about:"We have a team of experts who available 24/7 to guide you through our whatsApp chats."
},
{
 image:"/assets/Images/ic3.png",
 features:"100% TRUSTED SERVICE",
 about:"Transparency and accuracy is the most important for us and for our customer. We deal in the most transparent way."
},
{
 image:"/assets/Images/ic4.png",
 features:"INSTANT WITHDRAWAL & REFILL",
 about:"Taj4News provides instant service of id withdrawal & refilling with min to max amount."
},
{
 image:"/assets/Images/ic5.png",
 features:"AVAILABLE PAYMENT METHODS",
 about:"Multiple methods are available for Payments. Customer can pay via Net Banking, Bank Transfer, Paytm, Google pay, IMPS, PhonePe etc."
},
{
 image:"/assets/Images/ic6.png",
 features:"HIGH SECURITY",
 about:"Security is our Top priority and hence your data and imformation is safe and is not shared at any Point!"
}

]
export  {Betting,Contact}